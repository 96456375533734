<template>
  <v-card>
    <v-card-title>
      <v-spacer />
      <v-btn
        v-show="!loading"
        icon
        @click="() => tryFetchActions()"
      >
        <v-icon>
          {{ icons.mdiRefresh }}
        </v-icon>
      </v-btn>
      <v-progress-circular
        :value="progress"
        :size="50"
        :rotate="270"
        :indeterminate="loading"
        color="accent"
      >
        <span v-show="!loading">{{ countdown }}</span>
      </v-progress-circular>
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-model="selectedActions"
        hide-default-footer
        :mobile-breakpoint="0"
        :server-items-length="total"
        item-key="id"
        loading-text="Loading actions ..."
        show-expand
        :loading="loading"
        :items="actions"
        :headers="actionHeaders"
        :options.sync="tableOptions"
        :footer-props="{
          'items-per-page-options': pages,
          'show-first-last-page': true,
          'show-current-page': true,
        }"
      >
        <!-- SELECT ROW-->
        <template
          v-slot:item.task.account="{value}"
        >
          {{ value && value.phone }}
        </template>
        <template
          v-slot:item.task.message="{value}"
        >
          {{ value && value.text }}
        </template>
        <!-- SELECT ROW-->
        <template v-slot:header.data-table-select="{ props, on }">
          <CustomSelectRows
            :value="props"
            :page="tableOptions.page"
            :total="total"
            v-on="on"
          />
        </template>
        <template v-slot:item.task.groups="{ value }">
          {{ value && value.name }}
        </template>
        <template
          v-slot:item.task.schedule="{ value }"
        >
          {{ value }}
        </template>
        <template v-slot:item.task.enable="{ value }">
          <v-layout
            d-flex
            justify-end
          >
            <TrueFalse :value="value" />
          </v-layout>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td colspan="7">
            <v-data-table
              hide-default-footer
              :items="item.groups"
              :headers="groupHeaders"
            />
          </td>
        </template>
        <template v-slot:footer="{ props, on }">
          <TableFooter
            v-bind="props"
            v-on="on"
          />
        </template>
      </v-data-table>
    </v-card-text>

    <RetryActionModal
      ref="retryActionModal"
      @updated="commitEditAction"
    />
    <!-- <ActionEditModal
      ref="editActionModal"
      @update="tryUpdateAction"
    /> -->

    <ConfirmDialog
      ref="deleteActionConfirm"
      max-width="400"
    >
      <template v-slot="{agree, cancel}">
        <v-card>
          <v-card-title>Delete action</v-card-title>
          <v-card-text>
            Do you really want to delete action
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="error"
              @click="agree"
            >
              <v-icon>{{ icons.mdiDeleteForever }}</v-icon>
              Delete
            </v-btn>
            <v-btn
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-card>
</template>

<script>
import {
  mdiRefresh,
  mdiDeleteForever,
  mdiCheck,
  mdiCancel,
  mdiCheckCircleOutline,
  mdiLockOutline,
  mdiDotsVertical,
  mdiDotsHorizontal,
  mdiFilter,
  mdiPencil,
  mdiMagnify,
  mdiShieldOffOutline,
  mdiCellphoneKey,
  mdiEmailCheckOutline,
  mdiCloseCircleOutline,
  mdiCloseOctagonOutline,
  mdiClipboardTextOutline,
  mdiLinkVariantPlus,
  mdiGiftOpenOutline,
  mdiOpenInNew,
  mdiFormatListBulletedSquare,
  mdiReplay,
  mdiStop,
} from '@mdi/js'
import pick from 'lodash/pick'
import { mapActions, mapMutations, mapState } from 'vuex'
import { actionStatuses } from '@core/constants'
import DiscordApi from '@core/api/discord'
import store from '@/store'
import telegramStoreModule, { TELEGRAM_MODULE_NAME } from '@/store/modules/telegramStoreModule'

import CustomSelectRows from '@/components/CustomSelectRows.vue'

import ConfirmDialog from '@/components/ConfirmDialog.vue'
import RetryActionModal from '@/components/discord/action/RetryActionModal.vue'
import TrueFalse from '@/components/TrueFalse.vue'
import TableFooter from '@/components/TableFooter.vue'

export default {
  components: {
    CustomSelectRows,
    ConfirmDialog,
    RetryActionModal,
    TrueFalse,
    TableFooter,
  },
  data() {
    return {
      loading: true,
      intervalId: null,
      countdown: 60,
      types: [],
      actionStatuses,
      tableOptions: {
        search: this.$route.query.search || '',
        sortBy: this.$route.query.sortBy ? [this.$route.query.sortBy] : [],
        sortDesc: +this.$route.query.sortDesc ? [Boolean(+this.$route.query.sortDesc)] : [],
        page: +this.$route.query.page ? +this.$route.query.page : 1,
        itemsPerPage: +this.$route.query.limit ? +this.$route.query.limit : localStorage.getItem('itemsPerPage') || 50,
        status: this.$route.query.status,
      },
      search: '',
      selectedActions: [],
      total: 50,
      isProgress: true,
      pages: [50, 100, 200],
      verificationIcons: {
        'phone verified': mdiCellphoneKey,
        'mail verified': mdiEmailCheckOutline,
        'non verified': mdiShieldOffOutline,
        null: mdiCloseOctagonOutline,
      },
      typeIcons: {
        referral: mdiLinkVariantPlus,
        giveaway: mdiGiftOpenOutline,
        'white list': mdiFormatListBulletedSquare,
      },
      statusColors: {
        pending: 'blue-grey',
        in_progress: 'blue',
        success: 'light-green',
        skip: 'deep-purple',
        banned: 'deep-orange',
        locked: 'amber',
        error_captcha: 'teal',
        error_skip_captcha: 'pink darken-4',
        proxy_error: 'orange darken-4',
        error: 'red',
      },
      showFilters: false,
      icons: {
        mdiOpenInNew,
        mdiRefresh,
        mdiDotsVertical,
        mdiDotsHorizontal,
        mdiFilter,
        mdiDeleteForever,
        mdiPencil,
        mdiMagnify,
        mdiLockOutline,
        mdiCheck,
        mdiCloseCircleOutline,
        mdiCheckCircleOutline,
        mdiClipboardTextOutline,
        mdiCancel,
        mdiReplay,
        mdiStop,
      },
      groupHeaders: [
        {
          text: 'Channel',
          value: 'group',
        },
        {
          text: 'Error',
          value: 'error',
        },
        {
          text: 'Status',
          value: 'status',
        },
      ],
      actionHeaders: [
        {
          text: 'Account',
          value: 'task.account',
          sortable: true,
          filterable: true,
          align: 'start',
          width: '25%',
        },
        {
          text: 'Message',
          value: 'task.message',
          sortable: true,
          align: 'start',
          cellClass: 'py-2',
          width: '25%',
        },
        {
          text: 'Groups',
          value: 'task.groups',
          sortable: true,
          align: 'start',
          width: '20%',
        },
        {
          text: 'Schedule',
          value: 'task.schedule',
          sortable: true,
          align: 'start',
        },
        {
          text: 'Enable',
          value: 'task.enable',
          sortable: true,
          align: 'end',
        },
        {
          align: 'end',
          value: 'action',
          sortable: false,
        },
      ],
    }
  },

  computed: {
    ...mapState({
      actions(state) {
        return state[TELEGRAM_MODULE_NAME]?.action.actions
      },
    }),
    mappedOptions() {
      const opts = this.$route.query

      return opts
    },
    progress() {
      return this.countdown * 1.6
    },
  },
  watch: {
    total() {
      this.tableOptions.page = 1
    },
    tableOptions: {
      handler(value) {
        const query = pick(value, ['sortBy', 'page', 'search', 'status'])
        query.limit = value.itemsPerPage

        if (value.sortBy?.length) {
          [query.sortBy] = value.sortBy
        }

        if (value.sortDesc?.length) {
          [query.sortDesc] = value.sortDesc
        }

        Object.keys(query).forEach(key => {
          if (!query[key] && key !== 'sortDesc') {
            delete query[key]
          }
        })

        this.tryFetchActions(query)
        this.$router.replace({ query }).catch(() => {})
      },
      deep: true,
    },
  },

  mounted() {
    // Register module
    if (!store.hasModule(TELEGRAM_MODULE_NAME)) {
      store.registerModule(TELEGRAM_MODULE_NAME, telegramStoreModule)
    }
    this.tryFetchActions()
  },
  destroyed() {
    this.isProgress = false
    clearInterval(this.intervalId)
  },
  methods: {
    ...mapActions({
      fetchActions(dispatch, payload) {
        return dispatch(`${TELEGRAM_MODULE_NAME}/fetchActions`, payload)
      },
      updateAction(dispatch, payload) {
        return dispatch(`${TELEGRAM_MODULE_NAME}/updateAction`, payload)
      },
      removeAction(dispatch, payload) {
        return dispatch(`${TELEGRAM_MODULE_NAME}/removeAction`, payload)
      },
    }),

    ...mapMutations({
      commitSetActions(commit, payload) {
        return commit(`${TELEGRAM_MODULE_NAME}/SET_ACTIONS `, payload)
      },
      commitEditAction(commit, payload) {
        return commit(`${TELEGRAM_MODULE_NAME}/EDIT_ACTION`, payload)
      },
      commitDeleteAction(commit, payload) {
        return commit(`${TELEGRAM_MODULE_NAME}/REMOVE_ACTION`, payload)
      },
    }),

    totalStatus(statuses) {
      return Object.keys(statuses).reduce((total, key) => total + statuses[key], 0)
    },

    restartRefreshInterval() {
      this.countdown = 60
      clearInterval(this.intervalId)
      this.intervalId = setInterval(() => {
        this.countdown -= 1
        if (!this.countdown) {
          clearInterval(this.intervalId)
          this.tryFetchActions()
        }
      }, 1000)
    },

    async tryFetchActions(params = this.mappedOptions) {
      this.loading = true
      const { data } = await this.fetchActions(params)
      this.total = data.total
      this.loading = false
      if (this.isProgress) {
        this.restartRefreshInterval()
      }
    },

    async tryDeleteAction(id) {
      this.$refs.deleteActionConfirm.open().then(() => {
        this.commitEditAction({ id, loading: true })
        this.removeAction({ id }).then(() => {
          this.commitDeleteAction({ id })
        })
      })
    },

    async tryUpdateAction(value) {
      this.commitEditAction({ ...value, loading: true })
      const { data } = await this.updateAction({ action: value })
      this.commitEditAction(data)
    },

    async tryStopAction(id) {
      try {
        const { data } = await DiscordApi.Action.stopAction({ id })
        this.commitEditAction(data)
        this.$message.success('Action stopped')
      } catch (err) {
        this.$message.error(err)
      }
    },

    openActionEditModal(action) {
      this.$refs.editActionModal.open(action)
    },

    openRetryActionModal(action) {
      this.$refs.retryActionModal.open(action)
    },
  },
}
</script>

<style lang="scss" scoped>
.statuses{
  display: flex;
  .status{
    min-width: 10px;
    &:last-child{
      background: red;
    }
  }
}
</style>
