<template>
  <v-dialog
    v-model="isOpen"
    width="90vw"
    persistent
    @click:outside="tryClose"
  >
    <v-card>
      <v-card-title>
        Retry Action
        <v-spacer />
        <v-btn
          icon
          @click="tryClose"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <label>
            Statuses
          </label>
          <v-row>
            <v-col>
              <v-checkbox
                v-model="formData.statuses"
                label="Pending"
                disabled
                value="pending"
              ></v-checkbox>
              <v-checkbox
                v-model="formData.statuses"
                label="Skip"
                value="skip"
              ></v-checkbox>
              <v-checkbox
                v-model="formData.statuses"
                label="Banned"
                value="banned"
              ></v-checkbox>
              <v-checkbox
                v-model="formData.statuses"
                label="Locked"
                value="locked"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                v-model="formData.statuses"
                label="Error captcha"
                value="error_captcha"
              ></v-checkbox>
              <v-checkbox
                v-model="formData.statuses"
                label="Error skip captcha"
                value="error_skip_captcha"
              ></v-checkbox>
              <v-checkbox
                v-model="formData.statuses"
                label="Proxy error"
                value="proxy_error"
              ></v-checkbox>
              <v-checkbox
                v-model="formData.statuses"
                label="Error"
                value="error"
              ></v-checkbox>
            </v-col>
          </v-row>
          <ActionsDelay
            v-model="formData"
          ></ActionsDelay>
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="loading"
          @click="submit"
        >
          Retry
        </v-btn>
        <v-btn
          :disabled="loading"
          @click="tryClose"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'
import _cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import {
  maximumValue, maxMultiplyValue, simpleRequired, isMore, integerValidator,
} from '@core/utils/validation'
import DiscordApi from '@core/api/discord'
import ActionsDelay from '@/components/ActionsDelay.vue'

const DEFAULT_DATA = {
  statuses: [
    'skip',
    'banned',
    'locked',
    'error_captcha',
    'error_skip_captcha',
    'proxy_error',
    'error',
    'pending',
  ],
  account_delay: 15,
  batch_delay: 1,
  batch_size: 5,
}

export default {
  components: { ActionsDelay },
  data: () => ({
    icons: {
      mdiClose,
    },
    loading: false,
    isOpen: false,
    actionId: null,
    initFormData: DEFAULT_DATA,
    formData: DEFAULT_DATA,
  }),
  computed: {
    isChanged() {
      return !isEqual(this.initFormData, this.formData)
    },
  },
  methods: {
    maximumValue,
    maxMultiplyValue,
    simpleRequired,
    isMore,
    integerValidator,
    open(action) {
      this.isOpen = true
      this.actionId = action.id
      this.initFormDat = {
        ...DEFAULT_DATA,
        account_delay: action.account_delay,
        batch_delay: action.batch_delay,
        batch_size: action.batch_size,
      }
      this.formData = _cloneDeep(this.initFormDat)
    },
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          const { data } = await DiscordApi.Action.retryAction(
            this.actionId, {
              statuses: this.formData.statuses,
              account_delay: +this.formData.account_delay,
              batch_delay: +this.formData.batch_delay,
              batch_size: +this.formData.batch_size,
            },
          )
          this.$emit('updated', data)
          this.reset()
          this.$message.success('Action is retrying')
        } catch (error) {
          this.$message.error(error)
        } finally {
          this.loading = false
        }
      }
    },
    reset() {
      this.isOpen = false
      this.formData = _cloneDeep(this.initFormData)
    },
    async tryClose() {
      this.reset()
    },
  },
}
</script>

<style>

</style>
