var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"icon":""},on:{"click":function () { return _vm.tryFetchActions(); }}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiRefresh)+" ")])],1),_c('v-progress-circular',{attrs:{"value":_vm.progress,"size":50,"rotate":270,"indeterminate":_vm.loading,"color":"accent"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_vm._v(_vm._s(_vm.countdown))])])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","mobile-breakpoint":0,"server-items-length":_vm.total,"item-key":"id","loading-text":"Loading actions ...","show-expand":"","loading":_vm.loading,"items":_vm.actions,"headers":_vm.actionHeaders,"options":_vm.tableOptions,"footer-props":{
        'items-per-page-options': _vm.pages,
        'show-first-last-page': true,
        'show-current-page': true,
      }},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.task.account",fn:function(ref){
      var value = ref.value;
return [_vm._v(" "+_vm._s(value && value.phone)+" ")]}},{key:"item.task.message",fn:function(ref){
      var value = ref.value;
return [_vm._v(" "+_vm._s(value && value.text)+" ")]}},{key:"header.data-table-select",fn:function(ref){
      var props = ref.props;
      var on = ref.on;
return [_c('CustomSelectRows',_vm._g({attrs:{"value":props,"page":_vm.tableOptions.page,"total":_vm.total}},on))]}},{key:"item.task.groups",fn:function(ref){
      var value = ref.value;
return [_vm._v(" "+_vm._s(value && value.name)+" ")]}},{key:"item.task.schedule",fn:function(ref){
      var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"item.task.enable",fn:function(ref){
      var value = ref.value;
return [_c('v-layout',{attrs:{"d-flex":"","justify-end":""}},[_c('TrueFalse',{attrs:{"value":value}})],1)]}},{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var item = ref.item;
return [_c('td',{attrs:{"colspan":"7"}},[_c('v-data-table',{attrs:{"hide-default-footer":"","items":item.groups,"headers":_vm.groupHeaders}})],1)]}},{key:"footer",fn:function(ref){
      var props = ref.props;
      var on = ref.on;
return [_c('TableFooter',_vm._g(_vm._b({},'TableFooter',props,false),on))]}}]),model:{value:(_vm.selectedActions),callback:function ($$v) {_vm.selectedActions=$$v},expression:"selectedActions"}})],1),_c('RetryActionModal',{ref:"retryActionModal",on:{"updated":_vm.commitEditAction}}),_c('ConfirmDialog',{ref:"deleteActionConfirm",attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var agree = ref.agree;
      var cancel = ref.cancel;
return [_c('v-card',[_c('v-card-title',[_vm._v("Delete action")]),_c('v-card-text',[_vm._v(" Do you really want to delete action ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":agree}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteForever))]),_vm._v(" Delete ")],1),_c('v-btn',{on:{"click":cancel}},[_vm._v(" Cancel ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }